import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './LandingPage.module.css';
import { ExternalLink, NamedLink } from '../../components';

const features = [
  { description: <FormattedMessage id="LandingPage.features.videoCourse" /> },
  { description: <FormattedMessage id="LandingPage.features.sessions" /> },
  { description: <FormattedMessage id="LandingPage.features.symptoms" /> },
  { description: <FormattedMessage id="LandingPage.features.menopauseSupport" /> },
];

function SectionFeatures() {
  return (
    <>
      <div className={css.healthPlatform} id="join">
        <div className={css.contentWidth}>
          <div className={css.featuresContainer}>
            <div className={css.featuresLeft}>
              <h2>
                <FormattedMessage id="LandingPage.only" />
              </h2>
              <div className={css.registerNowBtn}>
                <ExternalLink
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfGBEpfeNORFaca47L4g8kuDLs3gEowoZLj3qkMGglbDvLZbw/viewform?usp=send_form"
                  className={css.registerBtn}
                >
                  <FormattedMessage id="LandingPage.startHere" />
                </ExternalLink>
              </div>
            </div>
            <div className={css.featuresRight}>
              <h2>
                <FormattedMessage id="LandingPage.findAnswersRelatedTo" />
              </h2>
              <ul>
                {features.map((feature, index) => (
                  <li key={index}>{feature.description}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionFeatures;
