import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Page, LayoutSingleColumn, Footer } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import { withViewport } from '../../util/uiHelpers';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { useConfiguration } from '../../context/configurationContext';
import css from './LandingPage.module.css';

//Static assets
import facebookImage from '../../assets/vivianlab-brandImage.png';
import twitterImage from '../../assets/vivianlab-brandImage.png';

//Section pages
import SectionHero from './SectionHero';
import SectionHowItWorks from './SectionHowItWorks';
import SectionFeatures from './SectionFeatures';
import SectionExtraFeatures from './SectionExtraFeatures';
import { isDoctorApproved } from '../../util/dataExtractors';
import { fetchCurrentUserHasListings } from '../../ducks/user.duck';
import SectionClients from './SectionClients';
import { decryptString } from '../../util/securityHelpers';

export const LandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    isAuthenticated,
    currentUser,
    currentUserHasPendingListings,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    // if (shouldSaveEnglishPage) {
    //   window.localStorage.setItem('locale', 'en');
    // }
    dispatch(fetchCurrentUserHasListings());
  }, []);

  const config = useConfiguration();
  const [scroll, setScroll] = useState(false);

  const siteTitle = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });

  const approvalContent = intl.formatMessage({
    id: 'LandingPage.approvalContent',
  });

  const schemaImage = `${config.marketplaceRootURL}${facebookImage}`;

  console.log(decryptString('��������ce', '14021997'))

  useEffect(() => {
    let isMounted = true;
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        if (isMounted) {
          setScroll(window.scrollY > 0);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        isMounted = false; // This is the cleanup for event listener
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const isHeaderSticky = scroll ? css.sticky : '';

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.marketplaceRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        isStaticPage={true}
        className={css.pageRoot}
        topbar={<TopbarContainer staticPageHeader={true} isHeaderSticky={isHeaderSticky} />}
        footer={<Footer />}
      >
        <div className={css.pageContent}>
          {isAuthenticated && !isDoctorApproved(currentUser) && currentUserHasPendingListings ? (
            <div className={css.profileVerification}>{approvalContent}</div>
          ) : null}
          <SectionHero isAuthenticated={isAuthenticated} />
          <SectionClients />
          <SectionHowItWorks />
          <SectionFeatures />
          <SectionExtraFeatures />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserHasListings, currentUserHasPendingListings } = state.user;
  const { isAuthenticated } = state.auth;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserHasListings,
    currentUserHasPendingListings,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(LandingPageComponent);

export default LandingPage;
