import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import pptShape1 from '../../assets/ppt-shape1.png';
import pptShape2 from '../../assets/ppt-shape2.png';
import css from './LandingPage.module.css';

function SectionExtraFeatures() {
  return (
    <>
      <div className={css.ppt2}>
        <span className={css.shape1}>
          <img src={pptShape1} alt="Access" />
        </span>
        <span className={css.shape2}>
          <img src={pptShape2} alt="Access" />
        </span>
        <div className={css.registerHealthcare}>
          <div className={css.contentWidth}>
            {[...Array(7).keys()].map((_, i) => (
              <p key={i}>
                <FormattedMessage id={`LandingPage.register${i + 1}`} />
                <br />
                <br />
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionExtraFeatures;
