import React from 'react';
import css from './LandingPage.module.css';
import { isLocaleGreek } from '../../util/genericHelpers';
import Slider from 'react-slick';
import clientlogo1 from '../../assets/client-logo1.png';
import clientlogo2 from '../../assets/client-logo2.png';
import clientlogo3 from '../../assets/client-logo3.png';
import clientlogo4 from '../../assets/client-logo4.png';

import '../../styles/slick/slick.css';
import '../../styles/slick/slick-theme.css';

function SectionClients(props) {

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4, // Display 4 items on desktop
    slidesToScroll: 1, // Scroll 1 item at a time
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1500, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 4,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1300, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 4,
          variableWidth: false,
        },
      },
      {
        breakpoint: 992, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 3,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };
  return (
    <div className={css.clientSection}>
      <div className={css.contentWidth}>
        <Slider {...settings}>
          <div className={css.clientLogo}>
            <img src={clientlogo1} alt="logo" />
          </div>
          <div className={css.clientLogo}>
            <img src={clientlogo2} alt="logo" />
          </div>
          <div className={css.clientLogo}>
            <img src={clientlogo3} alt="logo" />
          </div>
          <div className={css.clientLogo}>
            <img src={clientlogo4} alt="logo" />
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default SectionClients;
